import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import vsbl from 'vsbl';
import classNames from 'classnames';

const Image: FC<{
  source: string;
  alt?: string;
}> = ({ source, alt }) => {
  const [visible, setVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [mounted, setMounted] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null);
  const vsblRef = useRef<any>(null);

  useEffect(() => {
    setMounted(true);
    vsblRef.current = vsbl(imageRef.current)(() => {
      if (visible) return;
      setVisible(true);
    });
    vsblRef.current.update();
  }, []);

  const src = useMemo(() => `${source}?q=80&fl=progressive`, [source]);

  return (
    <div className="x y image__block">
      <img
        ref={imageRef}
        alt={alt}
        src={mounted ? src : null}
        className={classNames('x', {
          'is-visible': visible,
          'is-loaded': loaded
        })}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

export default React.memo(Image);
