import React, { FC, useEffect, useState, useMemo } from 'react';
import { ShopifyProduct, Shop, ShopifyProductType } from 'superclient';
import MetaModule from '../components/core/meta-module';
import ShopReturnModal from '../components/shop/shop-return-modal';
import ShopProductModal from '../components/shop/shop-product-modal';
import ProductDescription from '../components/shop/shop-product-details';
import { Superclient } from '../clients/superclient';
import ShopRelatedProducts from '../components/shop/shop-related-products';
import { useShopContext } from '../state/shop-context';

const TemplateProduct: FC<{
  pageContext: {
    shop: Shop;
    product: ShopifyProduct;
    products: ShopifyProduct[];
  };
}> = ({ pageContext: { product: _product, products: _products } }) => {
  const [product, setProduct] = useState(_product);
  const [products, setProducts] = useState(_products);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { productTypeFilter } = useShopContext();

  const { title, images, handle, description, descriptionHtml } = product;

  useEffect(() => {
    Superclient.getShopifyProduct(_product.id).then(setProduct);
    Superclient.getShopifyProducts().then(setProducts);
  }, [_product]);

  const filteredProducts = useMemo(() => {
    return products
      ?.filter((product) => _product.id !== product.id)
      .filter((product) => {
        if (productTypeFilter) {
          return product && product.productType === productTypeFilter;
        } else {
          return product.productType !== ShopifyProductType.Prints;
        }
      });
  }, [productTypeFilter, products]);

  const relatedProducts = filteredProducts?.filter(
    (relatedProduct) => relatedProduct.productType === product.productType
  );

  return (
    <div className="template__shop">
      <MetaModule
        title={title}
        path={`/products/${handle}`}
        description={description}
        customImage={images.length > 0 && { src: images[0].src }}
      />
      <div className="product">
        <div className="f jcb fw ais product__wrapper">
          <div className="product__images rel">
            {images.length >= 1 &&
              images.map((image, index) => (
                <>
                  {index === 0 ? (
                    <>
                      <img
                        className="x mb1"
                        key={image.id}
                        src={image.src}
                        alt={title}
                        data-zoom={image.src}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsGalleryOpen(true);
                          setSelectedImageIndex(index);
                        }}
                      />
                      <ProductDescription
                        pageContext={{ product: product, mobile: true }}
                      />
                    </>
                  ) : (
                    <img
                      className="x mb1"
                      key={image.id}
                      src={image.src}
                      alt={title}
                      data-zoom={image.src}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsGalleryOpen(true);
                        setSelectedImageIndex(index);
                      }}
                    />
                  )}
                </>
              ))}
          </div>
          <ProductDescription pageContext={{ product: product, mobile: false }} />
        </div>
      </div>
      <ShopReturnModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <ShopProductModal
        isOpen={isGalleryOpen}
        onClose={() => setIsGalleryOpen(false)}
        product={product}
        selectedImageIndex={selectedImageIndex}
      />
      {relatedProducts?.length > 0 ? (
        <ShopRelatedProducts
          key={product.id}
          product={product}
          relatedProducts={relatedProducts}
        />
      ) : null}
    </div>
  );
};

export default TemplateProduct;
