import React, { FC } from 'react';
import { Modal, Content } from 'micro-modal';

const ShopReturnModal: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose
}) => {
  return (
    <Modal
      className="modal z3"
      style={{ background: 'rgba(0,0,0,0.2)' }}
      open={isOpen}
      openClass="is-open"
      visibleClass="is-visible"
      hidingClass="is-hiding"
      closeTimeout={500}
      onClick={onClose}
      bodyClass="modal-is-visible"
    >
      <Content className="modal__content">
        <div>
          <h3>Return Policy</h3>
          <p>
            We do not accept returns. If there is an issue with your order, please
            email <a href="mailto:shop@supercluster.com">shop@supercluster.com</a>{' '}
            within 7 days of receipt. Supercluster is not responsible for items that
            are lost or damaged during shipping.
          </p>
        </div>
      </Content>
    </Modal>
  );
};

export default React.memo(ShopReturnModal);
