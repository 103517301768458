import React, { FC } from 'react';
import { ShopifyProduct } from 'superclient';
import ShopProductCard from './shop-product-card';

const ShopRelatedProducts: FC<{
  product: ShopifyProduct;
  relatedProducts: ShopifyProduct[];
}> = ({ product: currentProduct, relatedProducts }) => {
  return (
    <div className="related__products">
      <h4 className="m1">Related Products</h4>
      <div className="related__products__container f fw jcb m1">
        {relatedProducts.slice(0, 3).map((product) => (
          <ShopProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default React.memo(ShopRelatedProducts);
