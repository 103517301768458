import React, { FC, useCallback, useEffect, useState } from 'react';
import astrochimp from 'astrochimp';
import cookie from 'js-cookie';
import { ShopifyProduct, ShopifyProductVariant } from 'superclient';

const mailChimpNewsletterUrl =
  '//supercluster.us19.list-manage.com/subscribe/post?u=83b040af7bf23cbfec2452c69&amp;id=40d6e4659e';

const ShopProductDownload: FC<{
  product: ShopifyProduct;
  variant: ShopifyProductVariant;
}> = ({ product, variant }) => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const canDownload = cookie.get('sc_download');
    if (canDownload) {
      setSubmitted(true);
    }
  }, []);

  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();
    astrochimp(
      mailChimpNewsletterUrl,
      {
        EMAIL: email,
        MSIZE: variant.title,
        PRODUCT: product.title,
        WALLPAPER: product.title
      },
      (err) => {
        setSubmitted(true);
        cookie.set('sc_download', true, { expires: 365 });
        if (err) {
          console.log({ err });
        }
      }
    );
  }, []);

  return (
    <div>
      {submitted ? (
        <div className="al cw product__downloads">
          <div className="f fw jcs">
            {product.variants &&
              product.variants.map((variant) => (
                <a
                  target="_blank"
                  href={variant.image.src}
                  rel="noopener noreferrer"
                  className="product__downloads-single mb05 f jcc aic p05 block small mr1"
                  key={variant.id}
                >
                  {variant.title}
                </a>
              ))}
          </div>
        </div>
      ) : (
        <div className="product__waitlist cw">
          <p className="small">
            Please add your email to the waitlist to be notified.
          </p>
          <form className="f jcb aic rel" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className="btn">
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default React.memo(ShopProductDownload);
