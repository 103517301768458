import React, { FC, useEffect, useRef } from 'react';
import { Modal, Content } from 'micro-modal';
import { ShopifyProduct } from 'superclient';

const ShopProductModal: FC<{
  isOpen: boolean;
  product: ShopifyProduct;
  selectedImageIndex: number;
  onClose: () => void;
}> = ({ isOpen, product, selectedImageIndex, onClose }) => {
  const { images } = product;
  const gallery = useRef(null);

  useEffect(() => {
    if (!isOpen) return;

    const timeout = setTimeout(() => {
      if (!gallery.current) return;
      let imageOffset = 0;
      const windowWidth = window.innerWidth;

      for (let i = 0; i < selectedImageIndex; i++) {
        const image = images[i];
        const aspectRatio = image.width / image.height;
        const imageWidth = windowWidth;
        const imageHeight = imageWidth / aspectRatio;
        imageOffset += imageHeight;
      }

      const container = document.querySelector('.modal-gallery');
      container && container.scrollTo(0, imageOffset);
    }, 1);
    return () => clearTimeout(timeout);
  }, [isOpen]);

  return (
    <Modal
      className="modal-gallery z3"
      style={{ background: 'rgba(0,0,0,0.2)' }}
      open={isOpen}
      openClass="is-gallery-open"
      visibleClass="is-gallery-visible"
      hidingClass="is-gallery-hiding"
      closeTimeout={500}
      onClick={onClose}
      bodyClass="modal-gallery-is-visible"
    >
      <Content className="modal-gallery-is-visible__content">
        <div>
          <div className="productModalContainer" ref={gallery} onClick={onClose}>
            {images.map((image, index) => {
              const aspectRatio = image.width / image.height;

              return (
                <div
                  className="x"
                  id={`gallery-image-${index}`}
                  style={{
                    paddingTop: `${100 / aspectRatio}%`,
                    position: 'relative',
                    width: '100%'
                  }}
                  key={image.id}
                >
                  <img src={image.src} className="abs x y top left" />
                </div>
              );
            })}
          </div>
        </div>
      </Content>
    </Modal>
  );
};

export default React.memo(ShopProductModal);
