import React, { FC } from 'react';
import classNames from 'classnames';

const Button: FC<{
  className?: string;
  type?: string;
}> = ({ children, className, type, ...props }) => {
  const cn = classNames('button', className, {
    // 'button--outline': outline,
    // 'button--dark': dark,
    // 'button--light': light,
    // 'button--small': small
  });

  return (
    <button className={cn} {...props}>
      {children}
    </button>
  );
};

export default Button;
