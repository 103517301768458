import React, { FC, useCallback, useState } from 'react';
import astrochimp from 'astrochimp';
import { ShopifyProduct, ShopifyProductVariant } from 'superclient';

const mailChimpWaitlistUrl =
  '//supercluster.us19.list-manage.com/subscribe/post?u=83b040af7bf23cbfec2452c69&amp;id=ab1002f252';

const ShopWaitlistSignup: FC<{
  product: ShopifyProduct;
  variant: ShopifyProductVariant;
}> = ({ product, variant }) => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();
    astrochimp(
      mailChimpWaitlistUrl,
      {
        EMAIL: email,
        MSIZE: variant.title,
        PRODUCT: product.title
      },
      (err) => {
        setSubmitted(true);
        if (err) {
          console.log({ err });
        }
      }
    );
  }, []);

  return (
    <div>
      {submitted ? (
        <div className="al cw product__thanks">
          This item will be available soon.
        </div>
      ) : (
        <div className="product__waitlist cw">
          <p className="small">
            Please add your email to the waitlist to be notified.
          </p>
          <form className="f jcb aic rel" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className="btn">
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default React.memo(ShopWaitlistSignup);
