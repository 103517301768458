import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { deriveProductBasePrice, ShopifyProduct, Shop } from 'superclient';
import { useShopifyState } from '../../state/shopify-state-context';
import Button from '../../components/core/button';
import ShopReturnModal from '../../components/shop/shop-return-modal';
import ShopWaitlistSignup from '../../components/shop/shop-waitlist-signup';
import ShopProductDownload from '../../components/shop/shop-product-download';
import { Superclient } from '../../clients/superclient';

const ProductDescription: FC<{
  pageContext: {
    product: ShopifyProduct;
    mobile: Boolean;
  };
}> = ({ pageContext: { product: _product, mobile: _mobile } }) => {
  const { addItem } = useShopifyState();

  const [product, setProduct] = useState(_product);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);
  const [quantity, setQuantity] = useState(1);
  const [isMobile, setIsMobile] = useState(_mobile);
  const [addingState, setAddingState] = useState<'ready' | 'adding' | 'added'>(
    'ready'
  );

  const { title, images, handle, description, descriptionHtml } = product;

  const basePrice = useMemo(() => deriveProductBasePrice(product), [product]);

  useEffect(() => {
    Superclient.getShopifyProduct(_product.id).then(setProduct);
  }, [_product]);

  useEffect(() => {
    let timeout: any;
    if (addingState === 'added') {
      timeout = setTimeout(() => {
        setAddingState('ready');
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [addingState]);

  const isDownloadable = useMemo(() => {
    return product.tags.length >= 1 && product.tags.indexOf('downloads') > -1;
  }, [product]);

  const onDecreaseQuantity = useCallback(
    () => setQuantity(Math.max(quantity - 1, 1)),
    [quantity]
  );

  const onIncreaseQuantity = useCallback(
    () => setQuantity(Math.max(quantity + 1, 1)),
    [quantity]
  );

  const onAddToCart = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setAddingState('adding');
      addItem(selectedVariant.id, quantity).then(() => {
        setAddingState('added');
        setQuantity(1);
      });
    },
    [addItem, selectedVariant, quantity]
  );

  return (
    <div className={`product__desc${isMobile ? '__mobile' : ''} x`}>
      <div className="p1 product__info">
        <h3>{title}</h3>
        <div className="bcb p05 product__checkout">
          <div
            className={classNames('product__checkout-inner', {
              downloads: isDownloadable
            })}
          >
            {selectedVariant.available ? (
              <>
                <div className="mb015 f jcb aic product__form-qty fw">
                  <h6 className="caps product__form-label small m0 p0 cw">
                    Quantity
                  </h6>
                  <div className="quantityContainer f jcb aic product__form-qty">
                    <div
                      className="product__form-qty-select f jcc aic"
                      onClick={onDecreaseQuantity}
                    >
                      <span className="minus" />
                    </div>
                    <input
                      defaultValue={1}
                      value={quantity}
                      onChange={(e) =>
                        setQuantity(parseInt(e.currentTarget.value, 10))
                      }
                      name="quantity"
                      className="cw product__qty akkura mx015 small"
                    />
                    <div
                      className="product__form-qty-select f jcc aic"
                      onClick={onIncreaseQuantity}
                    >
                      <span className="plus" />
                    </div>
                    {isDownloadable ? (
                      <h4 className="product__price">Free Download</h4>
                    ) : (
                      <h4 className="product__price">
                        ${Math.round(basePrice).toFixed(2)}
                      </h4>
                    )}
                  </div>
                </div>
                <div className="cw mb1">
                  {product.variants &&
                    product.variants[0].title !== 'Default Title' && (
                      <div className="f jcb aic">
                        {isDownloadable ? (
                          <div>
                            Subscribe below to get access to downloadable wallpapers.
                          </div>
                        ) : (
                          <div className="f jcb aic x fw">
                            <h6 className="caps product__form-label small m0 p0">
                              Size
                            </h6>
                            <div className="product__circles f fw">
                              {product.variants.map((variant) => (
                                <div
                                  className={classNames(
                                    'product__circles-single f jcc aic small',
                                    {
                                      active: selectedVariant.id === variant.id
                                    }
                                  )}
                                  key={variant.id}
                                  onClick={() => setSelectedVariant(variant)}
                                  data-available={variant.available}
                                >
                                  {variant.title}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                </div>

                <form onSubmit={onAddToCart}>
                  <Button
                    type="submit"
                    className="product__submit b x p2 caps small"
                  >
                    {addingState === 'ready' && 'Add to Cart'}
                    {addingState === 'adding' && 'Adding...'}
                    {addingState === 'added' && 'Success!'}
                  </Button>
                </form>
              </>
            ) : (
              <>
                {isDownloadable ? (
                  <ShopProductDownload product={product} variant={selectedVariant} />
                ) : (
                  <ShopWaitlistSignup product={product} variant={selectedVariant} />
                )}
              </>
            )}
          </div>
        </div>
        <div
          className={`s16 ${isMobile ? 'pr1' : ''}`}
          dangerouslySetInnerHTML={{ __html: descriptionHtml }}
        />
        <span
          className={`block under ${isMobile ? 'mb1' : 'mt1'}`}
          onClick={(e) => {
            e.preventDefault();
            setIsModalOpen(true);
          }}
        >
          Return Policy
        </span>
      </div>
      <ShopReturnModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default ProductDescription;
