import React, { FC, useMemo } from 'react';
import { Link } from 'gatsby';
import { deriveProductBasePrice, ShopifyProduct } from 'superclient';
import Image from '../core/image';

const ShopProductCard: FC<{ product: ShopifyProduct }> = ({ product }) => {
  const { id, title, handle, images, availableForSale, tags } = product;

  const basePrice = useMemo(() => deriveProductBasePrice(product), [product]);

  return (
    <div key={id} className="rel product__card">
      <Link to={`/products/${handle}`} className="abs z1 top left x y" />
      <div className="product__card-image mb05 bcg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width={100}
          height={100}
          className="x y"
          style={{
            aspectRatio: '1'
          }}
        />
        <Image source={images[0].src} alt={title} />
      </div>
      <div className="f jcb aic mt05">
        <h6 className="caps small m0 p0 product__card-title ani">{title}</h6>
        <h6 className="tag caps p05 m0 bcg">
          {availableForSale ? (
            <span>${Math.round(basePrice)}</span>
          ) : (
            <div>
              {tags && tags.length >= 1 && tags.indexOf('downloads') > -1 ? (
                <span>Download</span>
              ) : (
                <span>Join Waitlist</span>
              )}
            </div>
          )}
        </h6>
      </div>
    </div>
  );
};

export default React.memo(ShopProductCard);
